(function () {

  "use strict";

  function DeviceSelector () {

    // private
    var media;

    // privileged functions can see private variables/functions
    this.init = function () {
      media = $(".js-device-selector:visible").data("devicetype");

      $("html")
      .removeClass("device-mobile")
      .removeClass("device-mobilePlus")
      .removeClass("device-tablet")
      .removeClass("device-laptop")
      .removeClass("device-desktop")
      .addClass("device-" + media);
    };

    this.getDeviceType = function () {
      return media;
    };

    this.mobileAll = function () {
      if ((media === 'mobile' || media === 'mobilePlus')) {
        return true;
      } else {
        return false;
      }
    };

    this.mobile = function () {
      if (media === 'mobile') {
        return true;
      } else {
        return false;
      }
    };

    this.mobilePlus = function () {
      if (media === 'mobilePlus') {
        return true;
      } else {
        return false;
      }
    };

    this.tablet = function () {
      if (media === 'tablet') {
        return true;
      } else {
        return false;
      }
    };

    this.mobileTablet = function () {
      if ((media === 'mobile' || media === 'mobilePlus' || media === 'tablet')) {
        return true;
      } else {
        return false;
      }
    };

    this.desktop = function () {
      if ((media === 'laptop' || media === 'desktop')) {
        return true;
      } else {
        return false;
      }
    };

    this.init();
  }

  window.DeviceSelector = new DeviceSelector();

})();
