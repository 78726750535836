(function () {

  "use strict";

  function Helper () {
    this.getPorts = function () {
      // $.ajax({
      //   url: 'http://demo.venezialines.com/api/ports',
      //   type: 'GET',
      //   dataType: 'application/json',
      //   success: function (response) {
      //     return response.ports;
      //   },
      //   error: function () {
      //     return false;
      //   },
      // });
    };

    this.getPortIDfromName = function (array, portName) {
      // return array.find(x => x.name === portName).id;
    };
  }

  window.Helper = new Helper();

})();
