(function ($) {

  "use strict";

  function Common () {
    let bindSmoothScrollToElement = function () {
      // handle links with @href started with '#' only
      $(document).on('click', 'a[href^="#"]:not([data-toggle="tab"])', function(e) {
        // target element id
        var id = $(this).attr('href');

        // target element
        var $id = $(id);
        if ($id.length === 0) {
          return;
        }

        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault();

        // top position relative to the document
        var pos = $id.offset().top - 80;

        if (DeviceSelector.desktop()) {
          pos -= 30;
        }

        // animated top scrolling
        $('body, html').animate({scrollTop: pos}, 800);
      });
    };

    let bindHambuger = function ($header, $hamb) {
      let $navigation = $('.js-nav', $header);

      $hamb.on('click', function () {
        let $self = $(this);

        $self.find('> div').toggleClass('active');
        $('body').toggleClass('mobile-nav-active');
        $navigation.toggleClass('active');
        $header.toggleClass('nav-open');
      });
    };

    let bindIntroVisibilityCheck = function () {
      let $window = $(window);
      let $introContent = $('.js-intro');
      let $mainContent = $('.js-main-content');

      function checkVisibility() {
        let $mainContentFirstArticle = $('article', $mainContent).first();
        let mainContentFirstArticleOffsetTop = $mainContentFirstArticle.offset().top;

        if (DeviceSelector.mobile()) {
          mainContentFirstArticleOffsetTop -= 150;
        }

        if (DeviceSelector.mobilePlus()) {
          mainContentFirstArticleOffsetTop -= 50;
        }

        if (DeviceSelector.tablet()) {}

        if (DeviceSelector.desktop()) {}

        $introContent.toggleClass('hide-it', $window.scrollTop() > mainContentFirstArticleOffsetTop);
      }

      checkVisibility();

      $(window).scroll(function () {
        checkVisibility();
      });
    };

    let init = function (parentElement) {
      let $header = $('.js-header', parentElement);
      
      if ($header.length) {
        let $hamb = $('.js-hamburger', $header);

        bindSmoothScrollToElement();
        bindHambuger($header, $hamb);
        bindIntroVisibilityCheck();

        //console.log(DeviceSelector.mobileTablet());

        if (DeviceSelector.mobileTablet()) {
          $('.js-nav-link').on('click', function () {
            $hamb.trigger('click');
          })
        }
      }
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.Common = new Common();

})(jQuery);
