(function ($) {

  "use strict";

  function SwiperSlider () {
    
    let initSlider = function ($swiper) {
      $swiper.each(function () {
        let $currentSwiper = $(this);

        let mySwiper = new Swiper($currentSwiper, {
          speed: 900,
          threshold: 30,
          slidesPerView: 'auto',
          centeredSlides: true,
          spaceBetween: 20,
          breakpoints: {
            // when window width is >= 468px
            468: {
              spaceBetween: 40,
            },
            // when window width is >= 768px
            768: {
              spaceBetween: 50,
            },
            // when window width is >= 1024px
            1024: {
              spaceBetween: 60,
            },
            // when window width is >= 1440px
            1440: {
              spaceBetween: 120,
            }
          }
        });

        mySwiper.slideTo(1, false, false);
      });
    };
    
    let init = function (parentElement) {
      // Single slider (one slider on all viewports)
      let $slider = $('.js-slider', parentElement);
      if ($slider.length) {
        initSlider($slider);
      }
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.SwiperSlider = new SwiperSlider();

})(jQuery);
